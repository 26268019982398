<template>
    <div class="background-section mb-0 mb-sm-5">
        <div class="background__image mb-3 mb-sm-5"></div>
    </div>
</template>

<script>
export default {
    name: 'BackgroundSection',
}
</script>

<style scoped>
.background__image{
    height: 250px;
    background-size: contain!important;
    background-repeat: no-repeat!important;
    background: url('~@/assets/building.png');
    transition: height 0.3s;
}
@media (min-width: 576px) {
    .background__image{
       height: 350px; 
       transition: height 0.3s;
    }
}
@media (min-width: 768px) {
    .background__image{
       height: 450px; 
       transition: height 0.3s;
    }
}
@media (min-width: 992px) {
    .background__image{
        background-size: cover!important;
        height: 600px; 
        transition: height background-size 0.3s;
    }
}
</style>